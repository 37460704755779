const ProductCard = ({
    colorBool,
    title,
    content,
    image,
    onClick
}) => {
    return (
        <div onClick={onClick} className="h-full w-full p-5">
            <div 
                className={`product shadow-md hover:shadow-xl`}
            >
                <div className="product-card-content flex flex-col justify-between gap-5 text-center">
                    <div className="product-card-title font-semibold uppercase">
                        {title}
                    </div>
                    {image && (
                        <img 
                            src={image} 
                            alt="LycheeGardens"
                            className="w-full rounded-xl"
                        />
                    )}
                    {content && (
                        <div className="product-card-desc">
                            {content}
                        </div>
                    )}
                </div>
                <span className="product-card-circle"></span>
            </div>

            {/* <div 
                className={`rounded-xl shadow-sm w-full h-full hover:shadow-xl p-5 ${
                    colorBool 
                    ? "bg-[#1B4720] text-white border-2 border-[#A8D07D] hover:border-transparent "
                    : "bg-white text-black border-2 border-transparent hover:border-[#A8D07D]"
                }`}
            >
                <div className="flex flex-col text-center gap-3">
                    <div className={`font-semibold uppercase ${colorBool ? "text-[#A8D07D]" : "text-[#184720]"}`}>
                        {title}
                    </div>
                    {image && (
                        <img 
                            src={image} 
                            alt="LycheeGardens"
                            className="w-full rounded-xl"
                        />
                    )}
                    {content && (
                        <div className="">
                            {content}
                        </div>
                    )}
                </div>
            </div> */}
        </div>
    );
}

export default ProductCard;