import { useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import SectionHeading from "../sectionHeading/SectionHeading";
import ProductCard from "../elements/ProductCard";
import { Products } from "../_data/Products";

// IMAGES 
import Product1 from "../../assets/images/site/lg-p1.png";
import Product2 from "../../assets/images/site/lg-p2.png";
import Product3 from "../../assets/images/site/lg-p3.png";


const ProductsList = () => {
    const {
        t
    } = useStateContext();

    const groupedProducts = Products(t).reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});

    const allProducts = Products(t);

    const [activeTab, setActiveTab] = useState("All Products");

    const scrollToCategory = (category) => {
        if (activeTab !== category) {
            setActiveTab(category);
        }
    };

    return (
        <>
            {/* <div className="w-full my-5 px-5"> */}
            {/* <div className="p-4 sm:px-6 lg:px-8"></div> */}

            <div className="w-full my-5">
                <div className="overflow-x-auto w-full bg-[var(--primary-color)] p-2 sm:px-6 lg:px-8 mb-4">
                    <div className="flex items-center h-full text-center flex-row">
                        <div
                            className={`font-semibold cursor-pointer mx-2 p-2 capitalize h-full ${activeTab === "All Products" ? "bg-[var(--secondary-color)] rounded-xl text-black px-3 w-full md:w-fit" : "text-white"
                                }`}
                            onClick={() => {
                                scrollToCategory("All Products");
                            }}
                        >
                            {t("all_products")}
                        </div>
                        {Object.keys(groupedProducts).map((category) => (
                            <div
                                key={category}
                                className={`font-semibold cursor-pointer mx-2 p-2 capitalize h-full ${activeTab === category ? "bg-[var(--secondary-color)] rounded-xl text-black px-3 w-full md:w-fit" : "text-white"
                                    }`}
                                onClick={() => scrollToCategory(category)}
                            >
                                {category}
                            </div>
                        ))}
                    </div>
                </div>
                <div key="All Products" id="All Products" className="px-6 lg:px-8">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                        {activeTab === "All Products"
                            ? allProducts.map((product, index) => (
                                <div key={index} className="p-4 rounded-xl shadow-md bg-white text-center flex flex-col justify-center items-center">
                                    <img src={product.product_img} alt="Lychee Gardens" className="h-[150px]" />
                                    <h3>{product.product_name}</h3>
                                    {/* <p>{product.product_desc}</p> */}
                                </div>
                            ))
                            : null}
                    </div>
                </div>
                {Object.keys(groupedProducts).map((category) => (
                    <div key={category} id={category} className="px-6 lg:px-8">
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                            {activeTab === category
                                ? groupedProducts[category].map((product, index) => (
                                    <div key={index} className="p-4 rounded-xl shadow-md bg-white text-center flex flex-col justify-center items-center">
                                        <img src={product.product_img} alt="Lychee Gardens" className="h-[150px]" />
                                        <h3>{product.product_name}</h3>
                                        {/* <p>{product.product_desc}</p> */}
                                    </div>
                                ))
                                : null}
                        </div>
                    </div>
                ))}

                {/* {Object.entries(groupedProducts).map(([category, products]) => (
                    <div key={category} className="my-5">
                        <div className="text-lg font-semibold my-2">
                            {category}
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                            {products.map((product, index) => (
                                <div key={index} className="p-4 rounded-xl hover:shadow-md hover:bg-white">
                                    <h3>
                                        {product.product_name}
                                    </h3>
                                    <p>
                                        {product.product_desc}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))} */}
            </div>
        </>
    );
};

export default ProductsList;
