import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const BACKEND_URL = process.env.REACT_APP_BASE_URL;
//   const [currentMode, setCurrentMode] = useState("light");
    const [activeMenu, setActiveMenu] = useState(true);
    const [allRoutes, setAllRoutes] = useState([]);
    const [langCode, setLangCode] = useState("en");
    const [fontFam, setFontFam] = useState("'Noto Sans', sans-serif");
    const [blurDarkColor, setBlurDarkColor] = useState("rgba(28,28,28,0.5)");
    const [blurLightColor, setBlurLightColor] = useState("rgba(238,238,238,0.5)");
    const [blurWhiteColor, setBlurWhiteColor] = useState("rgba(255,255,255,0.5)");

    // LANGUAGE 
    const { t, i18n } = useTranslation();

    const isArabic = (text) => {
        const regex = new RegExp(
            "[\u0600-\u06FF\u0750-\u077F\u08a0-\u08ff\uFB50-\uFDFF\uFE70-\uFEFF]+([\u0600-\u06FF\u0750-\u077F\u08a0-\u08ff\uFB50-\uFDFF\uFE70-\uFEFF\\W\\d]+)*",
            "g"
        );
        if (text) {
            return text.match(regex);
        } else {
            return false;
        }
    };

    const isEnglish = (text) => {
        const regex = new RegExp("^[\u0000-\u007F]+$", "g");
        if (text) {
            return text.match(regex);
        } else {
            return false;
        }
    };

    const langs = [
        {
            code: "en",
            title: "English",
            flag: "/assets/flags/english-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
        {
            code: "ar",
            title: "عربي",
            rtl: true,
            flag: "/assets/flags/arabic-flag.png",
            font: "'Noto Kufi Arabic', sans-serif",
            size: "12px",
        },
        {
            code: "fr",
            title: "French",
            flag: "/assets/flags/french-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
        {
            code: "de",
            title: "German",
            flag: "/assets/flags/english-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
        {
            code: "it",
            title: "Italian",
            flag: "/assets/flags/english-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
        {
            code: "ru",
            title: "Русский",
            flag: "/assets/flags/russian-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
        {
            code: "es",
            title: "Spanish",
            flag: "/assets/flags/english-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
        {
            code: "ua",
            title: "Українська",
            flag: "/assets/flags/ukraine-flag.png",
            font: "'Raleway', sans-serif",
            size: "12px",
        },
    ];

    const getLangDetails = (langCode) => {
        const language = langs.find((lang) => lang.code === langCode);
        if (language) {
            const { title, flag } = language;
            return { title, flag };
        } else {
            return null;
        }
    };

    const isLangRTL = (langCode) => {
        const language = langs?.find((lang) => lang?.code === langCode);
        let cssLang = "";
        let cssSize = "";
        if (language) {
            const { font, size } = language;
            cssLang = font;
            cssSize = size;
        } else {
            return null;
        }
        document.documentElement.style.setProperty("--font-family", cssLang);
        document.documentElement.style.setProperty("--font-size", cssSize);
        setFontFam(cssLang);
        if (language) {
            if (language?.rtl) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    useEffect(() => {
        document.documentElement.style.setProperty("--font-family", fontFam);
    }, [fontFam]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        BACKEND_URL,
        // currentMode,
        // setCurrentMode,
        isArabic,
        isEnglish,
        activeMenu,
        setActiveMenu,
        langs,
        langCode,
        setLangCode,
        allRoutes,
        setAllRoutes,
        isLangRTL,
        getLangDetails,
        fontFam,
        setFontFam,
        t,
        i18n,
        blurDarkColor,
        setBlurDarkColor,
        blurLightColor,
        setBlurLightColor,
        blurWhiteColor,
        setBlurWhiteColor,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
