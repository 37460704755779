import { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";

import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight
} from "react-icons/hi";

import MainParallax from "../../components/elements/MainParallax";
import hero1 from "../../assets/images/site/1.jpg";
import hero2 from "../../assets/images/site/2.jpg";
import hero3 from "../../assets/images/site/3.jpg";
import hero4 from "../../assets/images/site/4.jpg";
import hero5 from "../../assets/images/site/5.jpg";
import BgImg from "../../assets/images/bg/main-bg-002.png";
import Main from "../../components/home/Main";
import Hook from "../../components/elements/Hook";

import Contact from "../../components/contact/ContactComponent";
import ProductContent from "../../components/products/ProductContent";

import HookImage from "../../assets/images/site/lg-hook.jpg";
import AboutContent from "../../components/about/AboutContent";

const slidesData = [
  { imageSrc: hero1 },
  { imageSrc: hero2 },
  { imageSrc: hero3 },
  { imageSrc: hero4 },
  { imageSrc: hero5 }
];

const Slide = ({ imageSrc }) => (
  <div className="w-full h-full flex items-center justify-center">
    <img
      src={imageSrc}
      alt="Lychee Gardens Cairo Egypt"
      className="w-full h-[100vh] object-cover"
    />
  </div>
);

const CarouselButton = ({ disabled, clickHandler, icon, isLeftIcon }) => (
  <button
    onClick={clickHandler}
    disabled={disabled}
    className={`${isLeftIcon ? "left-[7%]" : "right-[7%]"
      } absolute top-[90%] md:top-[50%] -translate-y-1/2 p-2 bg-[--primary-color] text-white rounded-full shadow-lg hover:bg-[--secondary-color] disabled:opacity-50 disabled:cursor-not-allowed `}
  >
    {icon}
  </button>
);

const Carousel = ({
  slides,
  autoPlay = true,
  activeSlideDuration = 4000,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextActiveIndex, setNextActiveIndex] = useState(0);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        setNextActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, activeSlideDuration);
      return () => clearInterval(interval);
    }
  }, [slides.length, autoPlay, activeSlideDuration]);

  const nextSlide = () => setNextActiveIndex((activeIndex + 1) % slides.length);
  const prevSlide = () =>
    setNextActiveIndex((activeIndex - 1 + slides.length) % slides.length);
  const onCarouselIndicator = (index) => setNextActiveIndex(index);

  useEffect(() => {
    setActiveIndex(nextActiveIndex);
  }, [nextActiveIndex]);

  return (
    <div className="relative w-full h-[50vh] md:h-[70vh] lg:h-[100vh] overflow-hidden">
      {/* SLIDES */}
      {slides.map((el, i) => (
        <div
          key={i}
          className={`${i === activeIndex ? "block" : "hidden"
            } w-full h-full transition-all`}
        >
          <Slide {...el} />
        </div>
      ))}

      {/* CONTROL BUTTONS */}
      {slides.length > 1 && (
        <>
          <CarouselButton
            clickHandler={prevSlide}
            icon={<HiOutlineChevronDoubleLeft size={18} />}
            isLeftIcon
          />
          <CarouselButton
            clickHandler={nextSlide}
            icon={<HiOutlineChevronDoubleRight size={18} />}
            isLeftIcon={false}
          />
        </>
      )}
    </div>
  );
};

const Home = () => {
  const {
    t
  } = useStateContext();

  return (
    <>
      <section
        className={`parallax z-0 flex items-center justify-center`}
      >
        <Carousel
          slides={slidesData}
          autoPlay
          activeSlideDuration={4000}
          alignIndicators="bottom-2"
        />
        <div
          className={`fixed flex justify-center items-center flex-col left-[50%] z-0 m-0 uppercase text-center font-bold top-[25%] md:top-[35%] lg:top-[50%]
            }`}
          style={{
            transform: "translate(-50%, -50%)",
            fontSize: "3.5rem",
            color: "#FFFFFF",
            zIndex: "0",
          }}
        >
          <div
            className="flex flex-wrap"
            style={{
              fontSize: "3.5rem",
              // textShadow: "2px -2px rgba(255,255,255,0.5)",
              textShadow: "2px -2px rgba(168, 208, 125, 0.5), -2px 2px rgba(27, 71, 32, 0.5)",
              color: "#FFFFFF",
              zIndex: "0",
            }}
          >
            {t("brand_title")}
          </div>
        </div>
      </section>

      <div
        className="relative bg-gray-100 text-[#333333] min-h-[100vh]"
        style={{
          zIndex: 4,
        }}
      >
        {/* MAIN  */}
        <div className="p-4 sm:px-6 lg:px-8">
          <Main />
        </div>

        {/* HOOK  */}
        <Hook
          hookImage={HookImage}
          hookContent={t("hook_content")}
        />

        {/* PRODUCTS */}
        <div className="p-4 sm:px-6 lg:px-8">
          <ProductContent heading={true} />
        </div>

        {/* ABOUT */}
        <div className="p-4 sm:px-6 lg:px-8">
          <AboutContent heading={true} />
        </div>

        {/* CONTACT */}
        <Contact />
      </div>
    </>
  );
};

export default Home;
