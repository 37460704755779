import { useEffect } from "react";
import { useWindowScroll } from "react-use";

{/* <div></div> */ }
const MainParallax = ({ 
  title, 
  subtitle, 
  bg, 
  minHeight, 
  shade,
  shadeOpacity
}) => {
  return (
    <>
      {/* Parallax Section */}
      <section
        className={`parallax z-0 bg-cover bg-center flex items-center justify-center`}
        style={{
          backgroundImage: shade ? `linear-gradient(to right bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, ${shadeOpacity})), url(${bg})` : `url(${bg})`,
          minHeight: minHeight,
        }}
      >
        {/* <div
          className="text-white text-center"
          style={{
            fontFamily: "Helvatica",
            fontSize: "5rem",
          }}
        >
          {title}
        </div> */}
        <div
          className={`fixed flex justify-center items-center flex-col left-[50%] z-0 m-0 uppercase text-center font-bold ${
            minHeight === "100vh" ? "top-[50%]" : "top-[20%]"
          }`}
          style={{
            transform: "translate(-50%, -50%)",
            // fontFamily: "Helvatica",
            fontSize: minHeight === "100vh" ? "4rem" : "3rem",
            color: "#FFFFFF",
            zIndex: "0",
          }}
        >
          <div
            className="flex flex-wrap"
            style={{
              fontSize: minHeight === "100vh" ? "4rem" : "3rem",
              textShadow: "4px -4px rgba(255,255,255,0.1)",
              // color: "#1B4720",
              color: "#FFFFFF",
              zIndex: "0",
              // mixBlendMode: "overlay",
            }}
          >
            {title}
          </div>
          <div
          style={{
              fontSize: minHeight === "100vh" ? "2rem" : "1rem",
              textShadow: "4px -4px rgba(255,255,255,0.1)",
              // color: "#1B4720",
              color: "#FFFFFF",
              zIndex: "0",
              // mixBlendMode: "overlay",
            }}
          >
            {subtitle}
          </div>
        </div>
      </section>
      {/* <div 
        className="relative z-0 h-[100vh] overflow-hidden flex justify-center items-center bg-cover bg-center"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="absolute top-0 w-[100%] h-[100%]">
          <div className="relative h-[100%] w-[100%]"> 
            <h1 className="fixed top-[50%] left-[50%] z-0 m-0 uppercase text-center font-bold" 
            style={{
              transform: "translate(-50%, -50%)",
              fontFamily: "Helvatica",
              fontSize: "5rem",
              textShadow: "5px -5px rgba(25,42,46,0.06)",
              color: "#142124",
              mixBlendMode: "overlay",

            }}>
              Lychee&nbsp;Gardens
            </h1>
          </div>
        </div>
      </div> */}

    </>
  );
}

export default MainParallax;