import { useState, useEffect } from "react";
import Products from "./products";
import Nav from "../components/navbar/Nav";
import { 
    Route, 
    Routes 
} from "react-router-dom";
import Footer from "../components/footer/Footer";
import { useStateContext } from "../context/ContextProvider";
import Home from "./home";

import {
    RiWhatsappFill
} from "react-icons/ri";
import About from "./about";
import WhatsAppButton from "../components/elements/WhatsAppButton";
import ScrollToTopButton from "../components/elements/ScrollToTopButton";

const routes = [
    {
        path: "/home",
        element: <Home />,
        pageName: "Home",
    },
    {
        path: "/products",
        element: <Products />,
        pageName: "Products",
    },
    {
        path: "/about",
        element: <About />,
        pageName: "About",
    },
];

export let load = null;

function App() {
    const {
        setAllRoutes,
        i18n
    } = useStateContext();

    useEffect(() => {
        setAllRoutes(routes);
    }, []);

    useEffect(() => {
        setAllRoutes(routes);
        i18n.changeLanguage(localStorage.getItem("language"));
        document.body.style.direction = localStorage.getItem("direction");
        document.body.style.fontFamily = localStorage.getItem("font");
        document.documentElement.style.setProperty("--font-family", localStorage.getItem("font"));
    }, []);

    return (
        <>
            <WhatsAppButton />
            <ScrollToTopButton />

            <div
                style={{
                    margin: 0,
                    padding: 0,
                }}
            >
                {/* ANIMATION  */}
                <div className="flex" id="body-div" style={{ width: "100vw" }}>
                    <div
                        className={`w-[100%] overflow-x-hidden`}
                    >
                        <div className={`px-0`}>
                            <Nav />
                        </div>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            {routes.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={route.element}
                                    />
                                );
                            })}
                        </Routes>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default App;
