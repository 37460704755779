import { useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import SectionHeading from "../sectionHeading/SectionHeading";
import ProductCard from "../elements/ProductCard";
import { useNavigate } from "react-router-dom";

// IMAGES 
import Product1 from "../../assets/images/site/lg-p1.png";
import Product2 from "../../assets/images/site/lg-p2.png";
import Product3 from "../../assets/images/site/lg-p3.png";
import Product4 from "../../assets/images/site/lg-p4.png";

const ProductContent = ({
    heading
}) => {
    const {
        t
    } = useStateContext();
    const navigate = useNavigate();
    
    const handleProductClick = () => {
        console.log("Navigating to /products");
        navigate("/products");
    };

    return (
        <>
            <div className="w-full my-5 px-5">
                <div className="w-full h-full">
                    {heading && (
                        <SectionHeading
                            caption={t("product_caption")}
                            text={t("product_text")}
                        />
                    )}
                    <p className="font-light text-gray-600 leading-7">
                        {t("product_content")}
                    </p>
                    <div className="px-0 lg:px-20">
                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <ProductCard
                                colorBool={true}
                                title={t("product1_title")}
                                content={t("product1_content")}
                                image={Product1}
                                onClick={handleProductClick}
                            />
                            <ProductCard
                                colorBool={true}
                                title={t("product2_title")}
                                content={t("product2_content")}
                                image={Product2}
                                onClick={handleProductClick}
                            />
                            <ProductCard
                                colorBool={true}
                                title={t("product3_title")}
                                content={t("product3_content")}
                                image={Product3}
                                onClick={handleProductClick}
                            />
                            <ProductCard
                                colorBool={true}
                                title={t("product4_title")}
                                content={t("product4_content")}
                                image={Product4}
                                onClick={handleProductClick}
                            />
                        </div>
                    </div>
                    <p className="font-light text-gray-600 leading-7">
                        {t("product_content_end")}
                    </p>
                </div>
            </div>
        </>
    );
};

export default ProductContent;
