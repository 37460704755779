import React, { useState, useEffect } from 'react';
import { BsArrowUpCircleFill } from 'react-icons/bs';
import { useStateContext } from '../../context/ContextProvider';

const ScrollToTopButton = () => {
    const {
        i18n,
        isLangRTL
    } = useStateContext();

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setIsVisible(scrollTop > 100); // Adjust the scroll threshold as needed
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={`fixed bottom-[50px] p-1.5 bg-white text-[var(--primary-color)] rounded-full shadow-md cursor-pointer transition-opacity duration-300 flex items-center ${isVisible ? 'opacity-100' : 'opacity-0'
                } ${isLangRTL(i18n.language) ? "left-[7%]" : "right-[7%]"
                }`}
            style={{
                zIndex: 9,
            }}
            onClick={scrollToTop}
        >
            <BsArrowUpCircleFill size={40} style={{ zIndex: 10 }} />
        </div>
    );
};

export default ScrollToTopButton;
