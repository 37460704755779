import React, { useState } from "react";
import Logo from "../../assets/logo-2x.png";
import Certified from "../../assets/images/site/Certified.png";
import { useStateContext } from "../../context/ContextProvider";

import {
  BiGlobe,
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTiktok,
  BiLogoYoutube,
} from "react-icons/bi";
import $ from "jquery";

import { BsTelephone, BsEnvelopeAt, BsPinMap } from "react-icons/bs";
import CustomButton from "../elements/CustomButton";

const Footer = () => {
  const { t, BACKEND_URL } = useStateContext();

  const isMobile = window.innerWidth <= 800;

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  // HANDLE CHANGE
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted: ", formData);

    try {
      $.ajax({
        url: `${BACKEND_URL}backend/controllers/SendServiceMail.php`,
        method: "POST",
        contentType: "application/json",
        data: JSON.stringify(formData),
        dataType: "json",
        success: function (response) {
          console.log("Response from backend:", response);
          if (response.success) {
            console.log("Form submitted successfully.");
          } else {
            console.error("Backend returned an error:", response.status);
          }
          // navigate("/thankyou");
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error("AJAX Error:", textStatus, errorThrown);
          console.log("Response Text:", jqXHR.responseText);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="bg-[#184720] relative text-center text-white w-full pt-5"
      style={{
        zIndex: 4,
      }}
    >
      {/* LOGO  */}
      <div
        className="col-span-1 flex flex-col items-center p-5"
        style={{
          justifyContent: "flex-start",
          alignContent: "space-between",
        }}
      >
        <img src={Logo} className="h-24 w-auto mb-2" alt="Hikal Tech" />
        <p className="text-sm my-2">{t("brand_subtitle")}</p>
        {/* SOCIAL ICONS  */}
        <div className="gap-4 my-4 flex items-center justify-center">
          <a
            href="https://lycheegardens.com"
            className="w-full flex"
            style={{ justifyContent: "space-evenly" }}
            target="_blank"
          >
            <BiGlobe size={22} />
          </a>
          <a
            href="https://www.linkedin.com/in/amr-shaheen-6844b52a1"
            className="w-full flex"
            style={{ justifyContent: "space-evenly" }}
            target="_blank"
          >
            <BiLogoLinkedin size={22} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61556621192114"
            className="w-full flex"
            style={{ justifyContent: "space-evenly" }}
            target="_blank"
          >
            <BiLogoFacebook size={22} />
          </a>
          <a
            href="https://www.instagram.com/lycheegardens.eg"
            className="w-full flex"
            style={{ justifyContent: "space-evenly" }}
            target="_blank"
          >
            <BiLogoInstagram size={22} />
          </a>
          <a
            href="https://www.tiktok.com/@lycheegardens"
            className="w-full flex"
            style={{ justifyContent: "space-evenly" }}
            target="_blank"
          >
            <BiLogoTiktok size={22} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCjf_Vq6BDW9XipFHIq5Wfbg"
            className="w-full flex"
            style={{ justifyContent: "space-evenly" }}
            target="_blank"
          >
            <BiLogoYoutube size={22} />
          </a>
        </div>
      </div>
      {/* END - SOCIAL ICONS  */}

      {/* LOGO AND LINKS */}
      <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 pt-5 px-5">
        {/* CONTACT LINKS */}
        {/* <div className="justify-content-start text-left mt-2">
                        <div className="grid grid-cols-7 py-1">
                            <BsTelephone size={20} className="me-5 mt-1" />
                            <p
                                className="col-span-6"
                                style={{
                                    overflowWrap: "anywhere",
                                }}
                            >
                                <span className="text-nowrap">+20 100 113 0414</span>
                                <span className="mx-2">|</span>
                                <span className="text-nowrap">+20 100 777 4778</span>
                                <span className="mx-2">|</span>
                                <span className="text-nowrap">+20 120 458 0777</span>
                            </p>
                        </div>
                        <div className="grid grid-cols-7 py-1">
                            <BsEnvelopeAt size={20} className="me-5 mt-1" />
                            <p className="col-span-6">info@lycheegardens.com</p>
                        </div>
                        <div className="grid grid-cols-7 py-1">
                            <BsPinMap size={20} className="me-5 mt-1" />
                            <p className="col-span-6">
                                Arab Engineering Association, Farm No 50A, Belbais Road, Obour City, Cairo, Egypt
                            </p>
                        </div>
                    </div> */}
        {/* END - CONTACT LINKS */}

        {/* MAP  */}
        <div className="col-span-1 flex justify-center items-center p-1">
          {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d27560.40282003582!2d31.46199302461962!3d30.292628144931374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDE4JzExLjkiTiAzMcKwMjcnNTEuNCJF!5e0!3m2!1sen!2sae!4v1708326545554!5m2!1sen!2sae"
                        width="300"
                        height="300"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        style={{
                            border: 0,
                        }}
                    ></iframe> */}
          <img
            src={Certified}
            className="max-h-[350px]"
            alt="Lychee Gardens Certified"
          />
        </div>

        {/* DETAILS  */}
        <div
          className="col-span-1 flex flex-col items-center p-5 text-[#CCCCCC]"
          style={{
            justifyContent: "flex-start",
            alignContent: "space-between",
          }}
        >
          <h6 className="font-bold text-white uppercase">{t("contact")}</h6>
          <hr className="mt-1 mb-2 w-[70%]" />

          <form
            id="contact-form"
            className="w-full p-4 text-start flex flex-col gap-4"
            onSubmit={handleFormSubmit}
          >
            <input
              id="name"
              type="text"
              className="w-full"
              placeholder={t("name")}
              required
            />
            <input
              id="contact"
              type="text"
              className="w-full"
              placeholder={t("contact_number")}
              required
            />
            <textarea
              id="message"
              className="w-full"
              placeholder={t("message")}
              required
            />

            {/* <button
                            // onClick={handleFormSubmit}
                            className="w-full uppercase p-2 rounded-xl"
                        >
                            {t("submit")}
                        </button> */}

            <CustomButton
              onSubmit={handleFormSubmit}
              buttonText={t("submit")}
              buttonColor={"secondary"}
              borderColor={"white"}
            />
          </form>
        </div>
      </div>

      {/* COPYRIGHT  */}
      <hr className="mt-1 mb-2 w-full" />
      <div className={`flex items-center px-7 gap-1 justify-center pb-2`}>
        <div className="text-center text-sm p-2 uppercase">
          All Rights Reserved © {new Date().getFullYear()}
          <a
            href="https://hikaltech.com"
            className="font-semibold px-2 text-[var(--secondary-color)]"
          >
            Hikal Tech
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
